<template>
    <div class="flex column center" style="margin-bottom:4rem;">
        <div class="new_box">
            <div class="titlee">声纹鉴定为电信诈骗案件审理提供有力证据</div>
            <p class="time">2021-12-31</p>
            <p class="space_content">
                犯罪嫌疑人张某通过偷渡的方式出境至缅甸某地打工，经人介绍到某一诈骗窝点参与电信诈骗的犯罪活动，张某利用该诈骗窝点提供的场所和电脑等设备，还有有关诈骗对象的电话号码等信息，往我国境内打诈骗电话，其以发放贷款为幌子对受害人实施诈骗。
            </p>
            <p class="space_content">
                有一天，受害人王某接到一陌生电话，对方以某借贷软件的客服专员的身份与其联系。通过之前的信息获取了解到王某有资金周转的需求，而后双方添加微信，王某在其引导下下载了其所谓的借贷软件（实际软件是被张某所在的诈骗窝点操控），王某随后填写了自己的个人职业、身份证号、名下财产等信息，张某又以王某是第一次与公司有业务往来的理由，提出王某需要办理开通会员，开通后马上返还会员费；而后再以后台篡改其银行卡号的方式欺骗王某填写的银行卡号错误，再通知其上了信贷部的数据库，需要填写授权委托书的等方式骗取1万余人民币。经警方侦查，锁定了嫌疑人张某，张某对该事件矢口否认，因没有其他证据，案件侦查遇到了瓶颈。
            </p>
            <p class="space_content">
                故警方委托我所进行专业的声纹鉴定，通过警方提供的微信语音文件样本语音文件，我所鉴定人经过一系列的检验分析，最后确认为了送检的检材语音与样本张某语音是同一人所说。之后嫌疑人对此供认不讳，法院采纳了我所的鉴定意见。
            </p>
        </div>
        <div class="flex wrap col442" style="width:100%;text-align:left;margin-top:1rem">
            <div style="margin-bottom:1rem" class="col4 flex  start1">
                <div class="flex" style="margin-right:10%">
                    <p style="white-space:nowrap">上一篇：</p>无
                    
                </div>
            </div>
            <div style="margin-bottom:1rem" class="col4 flex  start1">
                <div class="flex">
                    <p style="white-space:nowrap">下一篇：</p>无
                    
                </div>
            </div>
            <div class="col2 flex  end1 center">
                <router-link class="a" style="color:#fff" :to="'/case/video_case'"><el-button type="primary">返回列表</el-button></router-link >
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return { 
            data:{

            }
        }
    },
}
</script>
<style scoped>
.space_content{
    margin-bottom:2rem;
    
}
.video_center{
    letter-spacing:2px;
    text-align:center;
    margin:2rem auto;
    max-width:800px;
}
</style>